#searchOverlay {
	position: fixed;
	width: 100%;
	height: 100vh;
	background: rgba($blue, .9);
	display: none;
	top: 0;
	left: 0;
	z-index: 100;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.search-inner{
		max-width: 1050px;
		margin: 0 auto;
		position: relative;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 100;
		display:flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	#closeSearch {
		position:absolute;
		top: 4rem;
		right: 2rem;
		background: none;
		border: none;
		text-transform: uppercase;
		color: $white;
		font-size: 16px;
		font-weight: 700;
		cursor: pointer;
		svg {
			margin-right: .5rem;

			g {
				fill: $white;
				stroke: $white;
			}
		}
	}
	#searchform {
		display:flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;

		.input-group {
			display:flex;
			flex-direction: row;
			width: 100%;
			justify-content: center;
			flex-wrap:nowrap;
			margin-right: 2rem;
			.input-group-button {
				width: 100px;
			}

			input.input-group-field {
				width: auto;
				padding-left: .75rem;
				margin-left: 1rem;
				margin-right: -1rem;
				max-width: 200px;
				@include breakpoint(400px, up) {
					max-width: 600px;
				}

				@include breakpoint(medium) {
					min-width: 500px;
				}
				&::placeholder {
					text-transform:uppercase;
				}
			}

			.button {
				width: 100px;
				@include breakpoint(641px) {
					width: 120px;
				}
				background: $white;
			}
		}
	}
}

body.search-active #searchOverlay {
	display:flex;
}

#searchResults {
	max-width: 950px;
	margin: 0 auto 4rem;

	.search-result {
		border-bottom: 2px solid $gray;
		padding: 2rem 0;
	}

	.search-result:last-of-type {
		border: none;
	}
	h2.entry-title {
		text-transform:uppercase;
		color: $gray;
	}
}