body {
	@include fluid-prop(--fluid-body, 14px, 18px);
	color: $gray;
	overflow-x: hidden;
	a {
		text-decoration: none;
	}

	p {
		line-height: 1.5;
	}
}

.body-controller {
	z-index: 5;
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
}

.page .entry-content {
	max-width: $global-max-width;
	width: 100%;
	margin: 0 auto;

	h2,
	h3,
	h4 {
		text-transform: uppercase;
		color: $med-gray;
		line-height: 1.2;
	}
}

ul.pagination {
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	li {
		padding: .25rem .5rem;
	}
}

.page-id-62 {
	.wp-block-column {
		max-height: 400px;
		overflow: hidden;
		margin-bottom: 1.5rem;
	}
	.wp-block-cover h4 a {
		color: $white;
		@include transition;

		&:hover {
			color: darken($white, 20%);

		}
	}
}

.entry-content {
	padding-top: 37px;
}

@media screen and (max-width: 767px) {
	input, select, textarea {
	  font-size: 16px;
	}
  }

  .wp-block-media-text {

	&.is-style-teaser-element {
	  display: grid;
	  grid-template-columns: 1fr;
	  grid-template-rows: 1fr;
	  max-height: 400px;

	  figure {
		max-height: 400px;
	}

	  .wp-block-media-text__media, .wp-block-media-text__content {
		grid-column: 1;
		grid-row: 1;
		margin: 0;
		max-height: 400px;
	  }

	  .wp-block-media-text__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		text-align:center;
		color: $white;
		font-family: $header-font-name;
		text-transform:uppercase;
		background: rgba(0,0,0,.5);
		max-height: 400px;

		width: 100%;
		padding: 0;
		p {
			max-height: 400px;
			width: 100%;
			font-size: var(--fluid-h4) !important;
			margin: 0;

			a {
				display:block;
				width: 100%;
				padding: 200px 1rem;
				color: $white;
				@include transition;
				max-height: 400px;

				&:hover {
					color: darken($white, 20%);
				}
			}
		}
	  }
	}
  }

  .page-id-62 {
	  .wp-block-media-text {
		  height: 100%;
		  background: rgba(0,0,0,.7);

		  figure {
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		  }
	  }
  }