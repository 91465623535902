#visitExplore,
#visitWaverly {
	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: $global-max-width;
		width: 100%;
		margin: 2rem auto;

		@include breakpoint(639px) {
			flex-wrap: nowrap;
		}

		.image {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			min-height: 276px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top center;
			border-top-left-radius: 95px;

			@include breakpoint(639px) {
				width: 50%;
			}

			h2 {
				color: $white;
				text-transform: uppercase;
				margin: 0;
				padding-left: 0.5rem;
				line-height: 0.7;
				font-size: 40px;
				font-weight: 700;
				padding-right: 1rem;
			}
		}
		.content {
			width: 100%;
			padding: 1rem 3rem;
			@include breakpoint(639px) {
				width: 50%;
			}
			.title {
				color: $med-gray;
				font-weight: 700;
				text-transform: uppercase;

				p {
					line-height: 1.2;
				}
			}
		}
	}
}

#visitEventCategories {
	padding: 0;
	.swiper-right,
	.swiper-left {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		z-index: 10;
		cursor: pointer;
	}

	.swiper-right {
		right: 0;
	}
	.swiper-left {
		left: 0;
	}
	.swiper-slide {
		z-index: 1;
		min-width: 297px;
	}
	.event-category-card {
		width: 100%;
		min-width: 297px;
	}
}

#visitPlan {
	max-width: $global-max-width;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 3rem;

	.inner {
		margin: 1rem auto 0;
		padding: 2rem;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	h2 {
		color: $white;
		text-transform: uppercase;
		line-height: 1;
		width: 100%;
	}
	h3 {
		text-transform: uppercase;
		line-height: 1;
		color: $white;
		margin-bottom: 1.5rem;
		width: 100%;
	}
	a {
		color: $white;
		text-transform: uppercase;
		border: 2px solid $light-tan;
		padding: 0.5rem 1.25rem;
		font-size: 16px;
		font-weight: bold;
		margin-right: 1.25rem;
		margin-bottom: 1rem;
		width: 100%;
		text-align: center;

		@include breakpoint(641px) {
			width: auto;
		}
	}
	iframe {
		width: 100%;
		margin-top: 1rem;
	}
	hr {
		background-color: $light-tan;
		width: 100%;
		height: 24px;
		border: none;
		margin: 0;
		padding: 0;
	}
}

#visitWaverly .row {
	.image {
		border-top-left-radius: 0;
		border-top-right-radius: 95px;
		min-height: 276px;
		align-items: flex-start;
		margin-top: 2rem;

		h2 {
			padding-left: 1.5rem;
			padding-right: 0;
		}
	}
}
