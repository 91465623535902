.error404 {
	.inner-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100vh;
		margin: 0 auto;
		width: 100%;
		max-width: $global-max-width;

		h1 {
			margin-bottom: 2rem;
			color: $white;
			text-transform: uppercase;
			font-size: 35px;
		}

		.content {
			background: $white;
			border-top-right-radius: 95px;
			max-width: 510px;
			padding: 2rem 5.25rem 4rem 2rem;

			h2 {
				text-transform: uppercase;
				color: $med-gray;
			}

			a {
				margin-top: 1.5rem;
				display: inline-block;
			}
		}
	}
}
