header#topbar {
	width: 100%;
	background: $light-tan;
	position: relative;
	z-index: 10;

	.inner {
		max-width: 1050px;
		width: 100%;
		margin: 0 auto;
		padding: 1rem 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 53px;
		position: relative;

		@include breakpoint(768px) {
			padding: 0.5rem 0;
		}

		#logo {
			width: 170px;
			display: block;
			position: absolute;
			transform: translateX(-50%);
			left: 50%;
			opacity: 1;
			transition: opacity 0.3s ease-in-out;

			a {
				position: absolute;
				top: 0.5rem;
			}
		}

		.left {
			padding: 0 1rem;

			a.social {
				margin-right: 0.5rem;
				display: none;

				@include breakpoint(768px) {
					display: inline;
				}

				svg {
					path {
						fill: $gray;
					}
				}
			}
			a#searchButton {
				text-transform: uppercase;
				align-items: center;
				font-size: 14px;
				font-weight: 700;
				color: $gray;
				display: inline-block;
				margin-left: 1rem;

				@include breakpoint(768px) {
					border-bottom: 2px solid $dark-tan;
				}

				span {
					margin-right: 0.5rem;
					margin-left: 0.2rem;
					color: #191919;
					letter-spacing: 1px;
					display: none;

					@include breakpoint(768px) {
						display: inline;
					}
				}
				svg {
					margin-right: 0.2rem;
					margin-bottom: 0.2rem;
				}
			}
		}
		.right {
			padding: 0 1rem;
			display: flex;
			flex-direction: row;
			align-content: center;
			align-items: center;
			min-width: auto;
			justify-content: flex-end;

			@include breakpoint(medium) {
				min-width: 287px;
			}

			.button {
				display: none;

				@include breakpoint(768px) {
					justify-content: space-between;
					display: flex;
				}
			}
		}
	}
}

#toggleMenu,
#closeMenu {
	border: none;
	background: none;
	cursor: pointer;
	margin-left: 2rem;
	width: 60px;
	margin-right: 5px;

	span {
		color: $black !important;
	}

	svg {
		height: 26px;
	}
}
#toggleMenu {
	svg#mobileHamburger {
		display: block;

		@include breakpoint(768px) {
			display: none;
		}
	}
	svg#hamburger {
		display: none;

		@include breakpoint(768px) {
			display: block;
		}
	}
}
#closeMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
	font-weight: 700;
	font-size: rem-calc(12px);
	display: none;
	padding-top: 1rem;
	@include breakpoint(768px) {
		padding-top: 0;
	}
}

#mainMenu {
	display: none;
	background: $light-tan;
	padding-top: 9rem;
	position: absolute;
	width: 100%;
	z-index: 1;
	top: 0;
	right: -800px;
	transition: right 0.3s ease-in-out;
	opacity: 1;
	max-width: 100%;
	overflow: hidden;

	@include breakpoint(768px) {
		transition: top 0.3s ease-in-out;
		top: -800px;
		right: 0;
		display:flex;
	}
	.inner {
		max-width: 1050px;
		width: 100%;
		margin: 0 auto;
		padding: 0.5rem 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		nav {
			width: 100%;
		}
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-around;
			width: 100%;

			li {
				margin-bottom: 1rem;

				.toggleMenuItem {
					display: none;
					background: none;
					border: none;
				}
				a {
					text-transform: uppercase;
					color: $black;
					font-weight: 500;
					font-size: rem-calc(20);
					@include transition();
					&:hover {
						color: lighten($black, 10%);
					}
				}
			}

			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				margin-top: 1rem;

				li a {
					font-weight: 500;
					text-transform: none;
					color: $gray;
					font-size: rem-calc(16);

					&:hover {
						color: lighten($gray, 10%);
					}
				}
			}
		}
	}
}

#mobileDonor {
	display: none;
	width: 100%;
	max-width: 300px;
	margin: 0 auto 1rem;
	font-size: 1.2rem;
	height: auto;
	padding: .75rem 1.5rem;
}
body.active {
	#toggleMenu {
		display: none;
	}
	#closeMenu {
		display: flex;
	}
	#mainMenu {
		display:flex;
		opacity: 1;
		top: 0;
		right: 0;
		position: absolute;

		@include breakpoint(768px) {
			top: 53px;
			right: 0;
		}
	}
	@include breakpoint(767px, down) {
		header#topbar .inner #logo {
			display:none;
		}
		#mobileDonor {
			margin-top: 4.5rem;

			display: flex;
		}
	}
	header#topbar .inner .left a#searchButton span {
		display: inline;
	}
}

@include breakpoint(767px, down) {
	#mainMenu {
		display: flex;
		padding-top: 4rem;
		z-index: 5;
		position:fixed;

		.inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				width: 100%;

				li {
					margin-bottom: 1.5rem;
					width: 100%;
					border-bottom: 1px solid $dark-tan;
					padding-left: 2rem;
					padding-bottom: 2rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					align-content: center;
					font-weight: 500;
					position:relative;

					&:last-child {
						border: none;
					}

					.toggleMenuItem {
						display: flex;
						justify-content: center;
						flex-direction: column;
						background: none;
						border: none;
						position: absolute;
						right: 2rem;
						cursor: pointer;
						padding: 1rem;
						top: -1rem;
						z-index: 5;

						&:after {
							content: "";
							display: block;
							background: url(../images/plus.svg);
							height: 19px;
							width: 21px;
							cursor: pointer;
						}

						&.active:after {
							background: url(../images/minus.svg) no-repeat center center;
						}
					}
				}

				ul {
					display: none;
					flex-direction: column;
					padding-left: 0;

					li {
						border: none;
						padding: 0;
					}
				}

				li.active ul {
					display: flex;
					flex-direction: column;

					li {
						width: 100%;
					}
				}
			}
		}
	}
}
