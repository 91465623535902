#impactTimeline {
	max-width: $global-max-width;
	width: 100%;
	margin: 3rem auto;

	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		flex-wrap: wrap;

		@include breakpoint(641px) {
			flex-wrap: nowrap;
		}

		.image {
			display: flex;
			flex-direction: row;
			width: 100%;
			padding-top: 0;

			@include breakpoint(641px) {
				padding-top: 3rem;
				width: calc(50% - 2px);
			}

			img {
				width: calc(100% - 2px);
				border: 1px solid $gray;
			}
		}

		.content {
			padding-top: 3rem;
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-left: 2rem;
			padding-bottom: 6rem;

			@include breakpoint(641px) {
				width: calc(50% + 2px);
				padding-bottom: 0;
				margin-left: 0;
			}

			.inner {
				max-width: 300px;
				width: 100%;
			}

			h2 {
				color: $gray;
				text-transform: uppercase;
				@include fluid-prop(font-size, 28px, 34px);
			}
			p {
				max-width: 300px;
				font-size: 18px;
			}
			.amount {
				background: $blue;
				color: $white;
				font-weight: bold;
				font-size: 47px;
				width: auto;
				display: inline-flex;
				font-family: $header-font-family;
				padding: 0.5rem 1rem;
			}
		}

		&.odd {
			.content {
				border-left: 4px solid $blue;
				order: 2;
				align-items: flex-start;
				padding-left: 2rem;

				.amount {
					margin-left: -3rem;
				}
			}
			.image {
				order: 1;
				justify-content: flex-end;

				img {
					margin-right: 0;

					@include breakpoint(641px) {
						margin-right: 2rem;
					}
				}
			}
		}
		&.even {
			.content {
				order: 2;
				border-left: 4px solid $blue;
				border-right: 0;
				padding-right: 0;
				align-items: flex-start;
				padding-left: 2rem;

				.amount {
					margin-right: 0;
				}

				@include breakpoint(641px) {
					align-items: flex-end;
					order: 1;
					border-right: 4px solid $blue;
					border-left: 0;
					padding-left: 0;
					padding-right: 1rem;

					.amount {
						margin-right: -2rem;
					}
				}
			}
			.image {
				order: 1;
				justify-content: flex-start;

				@include breakpoint(641px) {
					order: 2;

					img {
						margin-left: 2rem;
					}
				}
			}
		}

		&:first-child {
			.image,
			.content {
				padding-bottom: 0;
				padding-top: 3rem;

				@include breakpoint(641px) {
					padding-top: 0;
				}
			}

			.content {
				padding-bottom: 6rem;

				@include breakpoint(641px) {
					padding-bottom: 0;
				}
			}
		}
		&:last-child {
			.image,
			.content {
				padding-bottom: 0;

				@include breakpoint(641px) {
					padding-bottom: 6rem;
				}
			}
		}
	}
}
