footer#footer {
	background: $light-gray;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 4rem;

	.inner {
		max-width: $global-max-width;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;

		@include breakpoint(medium) {
			flex-wrap: nowrap;
		}

		.contact-left,
		.contact-right {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: flex-start;
			order: 1;
			flex-wrap: wrap;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.contact-right {
			justify-content: flex-start;
			align-items: start;
			font-size: 18px;

			.phone {
				color: $gray;
				line-height: 1.75;
				margin-bottom: 0.5rem;
				font-weight: 600;
				font-family: $body-font-family;
				color: $gray;
				margin-top: 0;

				a {
					font-weight: 600;
					font-family: $body-font-family;
					color: $gray;
				}
			}
			address {
				font-style: normal;
				line-height: 1.5;
				margin-bottom: 1rem;
			}
			a.button {
				min-width: 155px;
			}
		}
		#footerLogo {
			width: 120px;
		}

		@include breakpoint(767px, down) {
			.contact-left {
				width: 100%;
				margin-bottom: 2rem;
			}
			.contact-right {
				width: 50%;
			}
		}
		.socials {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin: 0.5rem 0;

			a.social {
				margin-right: 1rem;
				height: 22px;

				&:last-child {
					margin-right: 0;
				}

				svg {
					path {
						fill: $dark-tan;
					}
				}
			}
		}
	}
	.email {
		width: 100%;
		margin-top: 2rem;
		order: 3;

		@include breakpoint(medium) {
			margin-top: 0;
			width: auto;
			order: 2;
		}
		h4 {
			color: $med-gray;
			text-transform: uppercase;
			font-size: 20px;
		}
		p {
			margin-top: 0;
			font-weight: 500;
			font-size: 14px;
		}
	}
	.menu {
		order: 3;

		@include breakpoint(767px, down) {
			margin-top: 0;
			order: 2;
			width: 50%;
			justify-content: center;

			nav {
				padding-left: 2rem;

				ul {
					width: 100%;
				}
			}
		}

		li {
			margin-bottom: 0.5rem;
			a {
				font-weight: 600;
				color: $gray;
				font-family: $body-font-name;
			}
		}
	}
}
#mc_embed_signup {
	input {
		padding: .5rem .5rem;
		width: 100%;
		margin-bottom: 1rem;
	}
	input#mc-embedded-subscribe {
		min-width: 130px;
		cursor: pointer;
	}
}
#footerBottom {
	background: $gray;
	width: 100%;

	.inner {
		padding-top: 1rem;
		padding-bottom: 1rem;
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;

		@include breakpoint(medium) {
			flex-direction: row;
		}
		p {
			color: $white;
			margin: 0;
			font-size: 14px;
			&.terms {
				margin-left: 1rem;

				@include breakpoint(767px, down) {
					span {
						display: none;
					}
				}
			}

			a {
				font-weight: bold;
				color: $white;
			}
		}
	}
}
