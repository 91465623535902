section#homeHero {
	.inner {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		max-width: $global-max-width;
		margin: 0 auto;
		min-height: 545px;
		padding-bottom: 2rem;

		h1 {
			color: $white;

			span {
				color: $light-tan;
			}

			@include breakpoint(640px, down) {
				p br {
					display: none;
				}
			}
		}

		a.button {
			color: $white;

			&:hover {
				color: $white;
			}
		}
	}
}

section#homeEvents {
	background: $light-gray;
	padding: 1rem 0 3rem;
	position: relative;

	.about-text {
		width: 100%;
		max-width: 905px;
		padding: 1rem 0;
		margin: 0 auto;

		@include breakpoint(920px, down) {
			padding: 1rem;
		}
	}

	.announcement-section h2 {
		padding: 0;
	}

	h2 {
		max-width: $global-max-width;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		color: $med-gray;
		margin-bottom: 2rem;
	}
	.swiper-right,
	.swiper-left {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		z-index: 10;
		cursor: pointer;
	}

	.swiper-right {
		right: 0;
		padding-right: 0;

		svg {
			right: -9px;
			position: relative;
		}
	}
	.swiper-left {
		left: 0;
		padding-left: 0;
	}
	.swiper-slide {
		z-index: 1;
	}
}

section#homeActivities {
	max-width: $global-max-width;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 4rem;
	margin: 0 auto;

	h2 {
		color: $med-gray;
		font-family: $header-font-name;
		text-transform: uppercase;
		padding-bottom: 0.5rem;
	}

	.activities-grid {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.activities-cta {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
		min-height: 334px;
		margin-top: 3rem;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;

		@include breakpoint(768px) {
			border-top-left-radius: 95px;
			flex-wrap: nowrap;
			min-height: 350px;
		}

		h3 {
			padding-left: 4rem;
			color: $white;
			text-transform: uppercase;
		}
	}

	.info {
		border-top-left-radius: 95px;
		background: $light-tan;
		padding: 1.5rem 2.5rem 1.5rem 3rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 19px;
		line-height: 1.3;
		width: 100%;

		@include breakpoint(768px) {
			max-width: 300px;
			width: 100%;
			margin-left: 1rem;
		}
	}
}

section#homeCommunity {
	max-width: $global-max-width;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 4rem;
	margin: 0 auto;

	h2 {
		color: $med-gray;
		font-family: $header-font-name;
		text-transform: uppercase;
		padding-bottom: 0.5rem;
	}

	.row {
		display: flex;
		flex-direction: row;
		margin-bottom: 2rem;
		flex-wrap: wrap;

		@include breakpoint(639px) {
			flex-wrap: nowrap;
		}

		.left.image, .right.image {
			min-height: 225px;

			@include breakpoint(large) {
				min-height: calc(225px + 6rem);
			}
		}

		.image {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			min-height: 252px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;

			@include breakpoint(639px) {
				width: 33.3%;
			}

			h3 {
				color: $white;
				text-transform: uppercase;
				margin: 0;
				padding-left: 0.5rem;
				line-height: 0.7;
				font-size: 40px;
				font-weight: 700;
			}
		}
		.content {
			width: 100%;
			background: $light-gray;
			padding: 3rem;
			@include breakpoint(639px) {
				width: 66.6%;
			}
			.title {
				color: $med-gray;
				font-weight: 700;
				text-transform: uppercase;

				p {
					line-height: 1.2;
				}
			}

			&.top {
				border-top-right-radius: 95px;
			}
			&.bottom {
				border-bottom-left-radius: 95px;
			}
		}
		.right.content.top {
			order: 1;

			@include breakpoint(639px) {
				order: 2;
			}
		}
		.left.image.top {
			order: 2;

			@include breakpoint(639px) {
				order: 1;
			}
		}
		.left.content.bottom {
			order: 2;

			@include breakpoint(639px) {
				order: 1;
			}
		}
		.right.image.bottom {
			order: 1;

			@include breakpoint(639px) {
				order: 2;
			}
		}
	}
}
